import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import mainSliderJS from './includes/main_slider.js';
import textSliderJS from './includes/text_slider.js';
import facilitiesSliderJS from './includes/facilities_slider.js';
import facilitiesSliderSmallJS from './includes/facilities_slider_small.js';
import clientsSliderJS from './includes/clients_slider.js';
import facilitiesModal from './includes/facilities_modal.js';
import mainSliderImg1 from '../img/main-slider-1.jpg';
import mainSliderImg2 from '../img/main-slider-2.jpg';
import mainSliderImg3 from '../img/main-slider-3.jpg';
import mainSliderImg4 from '../img/main-slider-4.jpg';
import mainSliderImg5 from '../img/main-slider-5.jpg';
import mainGroupsImg1 from '../img/main-groups-1.jpg';
import mainGroupsImg2 from '../img/main-groups-2.jpg';
import mainGroupsImg3 from '../img/main-groups-3.jpg';
import mainGroupsImg4 from '../img/main-groups-4.jpg';
import mainGroupsImg5 from '../img/main-groups-5.jpg';
import mainGroupsImg6 from '../img/main-groups-6.jpg';
import shevronLeftWhiteImg from '../img/shevron-left-white.png';
import textBlockBkgImg from '../img/text-block-bkg.jpg';
import quotesLeftImg from '../img/quotes-left.png';
import companyPointImg1 from '../img/company-point-1.png';
import companyPointImg2 from '../img/company-point-2.png';
import companyPointImg3 from '../img/company-point-3.png';
import companyPointImg4 from '../img/company-point-4.png';
import companyPointImg5 from '../img/company-point-5.png';
import companyPointImg6 from '../img/company-point-6.png';
import companyPointImg7 from '../img/company-point-7.png';
import companyPointImg8 from '../img/company-point-8.png';
import facilitiesImg1 from '../img/facilities-1.jpg';
import facilitiesImg2 from '../img/facilities-2.jpg';
import facilitiesImg3 from '../img/facilities-3.jpg';
import facilitiesImg4 from '../img/facilities-4.jpg';
import facilitiesImg5 from '../img/facilities-5.jpg';
import facilitiesImg6 from '../img/facilities-6.jpg';
import facilitiesImg7 from '../img/facilities-7.jpg';
import facilitiesImg8 from '../img/facilities-8.jpg';
import facilitiesImg9 from '../img/facilities-9.jpg';
import facilitiesImg10 from '../img/facilities-10.jpg';
import facilitiesImg11 from '../img/facilities-11.jpg';
import facilitiesImg12 from '../img/facilities-12.jpg';
import techCatalogBkgImg from '../img/tech-catalog-bkg.jpg';
import techCatalogBkgMDImg from '../img/tech-catalog-bkg-md.jpg';
import techCatalogBkgXSImg from '../img/tech-catalog-bkg-xs.jpg';
import izovol1Img from '../img/clients/izovol1.jpg';
import metroImg from '../img/clients/metro.jpg';
import rioImg from '../img/clients/rio.jpg';
import audi_1Img from '../img/clients/audi_1.jpg';
import lentaImg from '../img/clients/lenta.jpg';
import leroyImg from '../img/clients/leroy.jpg';
import volvoImg from '../img/clients/volvo.jpg';
import pka_1Img from '../img/clients/5ka_1.jpg';
import ab_1Img from '../img/clients/ab_1.jpg';
import claasImg from '../img/clients/claas.jpg';
import efko1Img from '../img/clients/efko1.jpg';
import greenImg from '../img/clients/green.jpg';
import kia_1Img from '../img/clients/kia_1.jpg';
import mazdaImg from '../img/clients/mazda.jpg';
import miratorgImg from '../img/clients/miratorg.jpg';
import toyota1_1Img from '../img/clients/toyota1_1.jpg';
import closeImg from '../img/close.png';
import facilitiesLogo1Img from '../img/facilities-logo-1.png';
import facilitiesDetail1Img from '../img/facilities-detail-1.jpg';
import npfGroupImg from '../img/NPF-Group.png';
