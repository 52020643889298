function facilitiesSliderSmall(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('facilitiesSliderSmall: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('facilitiesSliderSmall: no sliderNavs found by class "navs"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: false,
        infinite: true,
        dots: true,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        responsive: [
    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const facilitiesSliderSmallNodes = document.getElementsByClassName('facilities-slider-small');
    for(let i = 0; i < facilitiesSliderSmallNodes.length; i++) {
        let facilitiesSliderSmallNode = facilitiesSliderSmallNodes.item(i);
        const facilitiesSliderSmallObj = new facilitiesSliderSmall(facilitiesSliderSmallNode);
    }
});
