function facilitiesSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('facilitiesSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('facilitiesSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: false,
        infinite: true,
        dots: true,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        responsive: [
    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const facilitiesSliderNodes = document.getElementsByClassName('facilities-slider');
    for(let i = 0; i < facilitiesSliderNodes.length; i++) {
        let facilitiesSliderNode = facilitiesSliderNodes.item(i);
        const facilitiesSliderObj = new facilitiesSlider(facilitiesSliderNode);
    }
});
