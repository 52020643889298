function clientsSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('clientsSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('clientsSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 7,
		slidesToScroll: 7,
        arrows: false,
        infinite: true,
        dots: true,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        responsive: [
			{
                breakpoint: 1440,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
			},
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                }
			},
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
			},
   		]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const clientsSliderNodes = document.getElementsByClassName('clients-slider');
    for(let i = 0; i < clientsSliderNodes.length; i++) {
        let clientsSliderNode = clientsSliderNodes.item(i);
        const clientsSliderObj = new clientsSlider(clientsSliderNode);
    }
});
