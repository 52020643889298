var popupS = require('popups');
import popupSBasicStyles from "../../../node_modules/popups/css/popupS.min.css";

function facilitiesDetailSlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('facilitiesDetailSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('facilitiesDetailSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');

    $(sliderContainer).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
        arrows: false,
        infinite: true,
        dots: true,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        responsive: [
    	]
	});
}

function facilitiesModalInit(facilitiesButton) {
    if (facilitiesButton === undefined || !facilitiesButton) {
        console.log('facilitiesModalInit: no valid facilitiesButton specified', facilitiesButton);
        return false;
    }
    let facilitiesID = facilitiesButton.getAttribute("href");
    facilitiesID = facilitiesID.substring(1); // remove #
    const facilitiesModalContent =  document.getElementById(facilitiesID);
    if (facilitiesModalContent === undefined || !facilitiesModalContent) {
        console.log('facilitiesModalInit: no facilitiesModalContent found by id ', facilitiesID);
        return false;
    }
    const facilitiesDetailSliderNode = facilitiesModalContent.getElementsByClassName('facility-detail-slider')[0];
    if (facilitiesDetailSliderNode === undefined || !facilitiesDetailSliderNode) {
        console.log('facilitiesModalInit: no facilitiesDetailSliderNode found by class "facility-detail-slider"');
        return false;
    }
    facilitiesButton.addEventListener('click', function(e) {
        e.preventDefault();
        popupS.modal({
            content: facilitiesModalContent,
            additionalCloseBtnClass: 'facilities-modal-close',
            additionalBaseClass: 'facilities-modal-base',
            additionalPopupClass: 'facilities-modal-popup',
            closeBtn: '<span class="close"><img src="img/close.png" alt="close icon"/></span>',
            onOpen: function(){
                const facilitiesDetailSliderObj = new facilitiesDetailSlider(facilitiesDetailSliderNode);
            },
        });
    });
}

document.addEventListener('DOMContentLoaded', function(){
    const facilitiesButtons = document.getElementsByClassName('facilities-modal-init');
    for(let i = 0; i < facilitiesButtons.length; i++) {
        let facilitiesButton = facilitiesButtons.item(i);
        facilitiesModalInit(facilitiesButton);
    }
});
